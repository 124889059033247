<template>
    <b-row>
        <b-col>
            <b-card>
                <b-card-header>
                    <h4 class="text-center">Konfirmasi Campaign</h4>
                </b-card-header>
                <b-card-body>
                    <a href="javascript:;" @click="back()">Back</a>
                    <hr>
                    <b-row class="mb-1">
                        <b-col cols="2">
                            <b-img
                                style="width:100px;height:100px"
                                :src="campaign.banner"
                                rounded
                                alt="Rounded image"
                                class="d-inline-block mr-1 mb-1"
                                />
                        </b-col>
                        <b-col cols="10">
                            <h5>Nama Campaign</h5>
                            <h4>{{ campaign.name }}</h4>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col>
                            <h5>Biaya Campaign</h5>
                            <b-row class="mb-1">
                                <b-col cols="6">Biaya Proyek</b-col>
                                <b-col cols="6" class="float-right">{{ formatCurrency(estimasi) }}</b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="6">PPN 10%</b-col>
                                <b-col cols="6" class="float-right">{{ formatCurrency(ppn) }}</b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row class="mt-4 text-center">
                        <b-col cols="12">
                            <h5 class="mb-1">Total Bayar</h5>
                            <h4>{{ formatCurrency(parseInt(estimasi) + parseInt(ppn)) }}</h4>
                            <br><br>
                            <p>Biaya campaign yang tidak terpakai akan dikembalikan ke saldo</p>
                        </b-col>
                        <b-col md="6">
                            <div class="demo-inline-spacing">
                                <router-link to="/campaign"
                                    tag="button"
                                    class="btn btn-outline-primary btn-block"
                                    >
                                    Simpan Sebagai Draft
                                </router-link>
                            </div>
                        </b-col>

                        <b-col md="6">
                            <div class="demo-inline-spacing">
                                <b-button @click="bayar" block variant="primary">
                                    Proses Pembayaran
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-card-body>
            </b-card>
        </b-col>

        <b-modal cancel-title="Ok" ok-title="Top Up" @ok="gotoTopUpPage" ref="my-modal-credit-tidak-cukup" title="Peringatan">
            <p>Kredit Tidak Cukup</p>
        </b-modal>

        <b-modal @ok="processPembayaran" ref="my-modal-credit-cukup" title="Peringatan">
            <p>Apakah Anda yakin akan melakukan proses pembayaran menggunakan saldo kredit?</p>
        </b-modal>

        <b-modal cancel-title="Top Up Kredit" @cancel="gotoTopUpPage" ok-title="Ajukan Termin" @ok="gotoAjukanTermin" ref="my-modal-verified-credit-tidak-cukup" title="Peringatan">
            <p>Gagal menayangkan campaign karena saldo kredit kamu tidak cukup</p>
            <p>Silahkan untuk top up kredit atau ajukan termin pembayaran karena kamu sudah verified</p>
        </b-modal>

        <b-modal cancel-title="Bayar Dengan Kredit" @cancel="processPembayaran" ok-title="Ajukan Termin" @ok="gotoAjukanTermin" ref="my-modal-verified-credit-cukup" title="Peringatan">
            <p>Silahkan Pilih Pembayaran</p>
        </b-modal>
    </b-row>
</template>

<script>
import {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BImg,
    BButton
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
export default {
    name: 'CampaignCreateStep5',
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BImg,
        BButton
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            estimasi: null,
            ppn: null,
            campaign: {},
            messageModal: null,
            is_verified: null,
            balance: null
        }
    },
    beforeMount() {
        let _ = this
        _.estimasi = localStorage.getItem('estimasi')
        _.ppn = parseInt(localStorage.getItem('estimasi')) * 0.1

        _.getCampaignDetail()
        _.getProfile()
    },
    methods: {
        bayar() {
            let _ = this
            if ( _.is_verified ) {
                if ( parseInt(_.balance) >= (parseInt(_.estimasi) + parseInt(_.ppn)) ) {
                    this.$refs['my-modal-verified-credit-cukup'].show()
                } else {
                    this.$refs['my-modal-verified-credit-tidak-cukup'].show()
                }
            } else {
                if ( parseInt(_.balance) >= (parseInt(_.estimasi) + parseInt(_.ppn)) ) {
                    this.$refs['my-modal-credit-cukup'].show()
                } else {
                    this.$refs['my-modal-credit-tidak-cukup'].show()
                }
            }
        },
        getProfile() {
            let _ = this
            axios.get('/advertiser/profile')
                .then(resp => {
                    console.log(resp.data.data)
                    let data = resp.data.data
                    _.is_verified = data.account.verified
                    _.balance = data.creditBalance
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getCampaignDetail() {
            let _ = this
            axios.get('/advertiser/campaigns/' + localStorage.getItem('campaignID'))
                .then(resp => {
                    _.campaign = resp.data.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        processPembayaran() {
            axios.post('/advertiser/campaigns/process-payment', {id: localStorage.getItem('campaignID')})
                .then(resp => {
                    console.log(resp)
                    this.$router.push({name: 'campaign', params: { paymentSuccess: true }})
                })
                .catch(err => {
                    console.log(err.response)
                    if ( err.response ) {
                        if ( err.response.data.error_code ) {
                            this.messageModal = err.response.data.message
                            // check if verified & no credit show modal 
                            let userdata = JSON.parse( localStorage.getItem('userData') )

                            if ( userdata.verified ) {
                                this.$refs['my-modal-verified-credit-tidak-cukup'].show()
                            } else {
                                this.$refs['my-modal-credit-tidak-cukup'].show()
                            }
                        }
                    }
                })
        },
        gotoTopUpPage() {
            let _ = this
            _.$router.push({name: 'top-up-credit', query: {campaignId: _.campaign.id}})
        },
        gotoAjukanTermin() {
            let _ = this
            _.$router.push({name: 'create-termin', query: {campaignId: _.campaign.id}})
        },
        formatCurrency(n = null) {
            return n ? 'Rp ' + parseInt(n).toLocaleString("id-ID") + ',00' : '-'
        },
        back() {
            let _ = this
            let campaignID = localStorage.getItem('campaignID') || _.$route.params.id
            _.$router.push({name: 'campaign-edit-4', params: {id: campaignID, forceEdit: true }})
        }
    }
}
</script>